<template>

  <ul class="seeroo-grid grid gap-4 xl:grid-cols-6 lg:grid-cols-5 md:grid-cols-4 grid-cols-3" >
    <li v-for="item in characters" :key="item.id" >
      <div :class="`ch-item ch-item-t1 ch-img-1`" :style="`background: url(${imageUrl(item.image)}); background-color: ${item.color};`">
        <div class="ch-info">
          <h3>{{ item.name }}</h3>
          <p><router-link :to="`/character/anime/list/${item.id}`">Цааш үзэх</router-link></p>
        </div>
      </div>
    </li>
  </ul>
</template>

<script>
import {imageUrl} from "../../utils";
import api from "../../store/_boot/baseApi"

export default {
  components: {},
  setup() {

  },
  data() {
    return {
      characters: []
    }
  },

  computed: {},

  created() {
    this.getCharacters();
  },

  watch: {},

  methods: {
    imageUrl,

    getCharacters() {
      api.get(`/api/m/character/type/${this.$route.params.character}`).then(({data}) => {
        this.characters = data;
      })
    }
  }
};
</script>
<style>
</style>